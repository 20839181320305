import { default as $ } from 'jquery';
import pickadate from "pickadate"

const onSetDate = (ctx) => {
    console.log(ctx)
}

$(document).ready( function() {
    $('.-onChangeSubmit').change(function(e) {
       e.preventDefault();
       $(this).closest('form').submit();
    })
    
    // console.log(pickadate)
    //
    // $(".content-filter-calendar-toggle").pickadate({
    //     onOpen: () => console.log('open'),
    //     onSet: onSetDate
    // });
});